@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Malayalam:wght@100;400&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html {
        font-family: 'Raleway', sans-serif;
    }
}

.will-change-transform {
  will-change: transform;
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite;
}
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
